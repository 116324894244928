import React, { useState } from 'react'
import { Layout, Button, Input, message} from 'antd';
import './notif.css';
import "antd/dist/antd.css";
import SiderBar from '../../components/Layout/SiderBar';
import axios from 'axios';

const Notifications = () => {

    const { TextArea } = Input;
    const {Header, Content} = Layout;
    const [title      , titleEdit] = useState("");
    const [description, descEdit ] = useState("");
    const pushUrl = 'https://us-central1-observatorio1873-dev.cloudfunctions.net/push-notifications/send/notifications';
   
    const success = () => {
        message.success({
            content: "Se envió correctamente.",
            style: {
              marginTop: '15px',
            },
        });
    };

    const error = () => {
        message.error({
            content: "No se envio la notificación.",
            style: {
              marginTop: '15px',
            },
        });
    };

    const titleHandler = (e) => {
        titleEdit(e.target.value)
    }

    const descriptionHandler= (e) =>{
        descEdit(e.target.value)
    }

    const send = () =>{
        if(title === "" || description === ""){
            error();
            return;
        }
        axios.get(pushUrl, { params: {
            title : title,
            body : description
        }}).then( (resp) => {
            titleEdit("");
            descEdit("");
            if(resp.status === 200){
                success();
                return;
            }
            throw "error"
        }).catch( (e) => {
            error();
            return;
        })
        
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <SiderBar
                active = {3}
            />
            <Layout
                className="site-layout"
            >
                <Header
                    className="site-layout-background"
                    style={{ padding: 0 }}
                />
                <Content
                    style={{ margin: '0 16px' }}
                >
                    <h4 className="section-titl">Notificaciones</h4>

                    <div 
                        className="site-layout-background notifications"
                    >
                        <div className="card-edit subsection-height">
                           <div className="label">
                               Título
                                <Input
                                    placeholder="Título"
                                    maxLength={30}
                                    className="edit-name"
                                    onChange={titleHandler}
                                    value={title}
                                />
                            </div>
                            <h5 className="desc-title">Descripción</h5>
                            <TextArea
                                placeholder="Escribir descripción"
                                autoSize={{ minRows: 8, maxRows: 8 }}
                                className="description"
                                maxLength={1000}
                                onChange={descriptionHandler}
                                value={description}
                            />
                            <Button
                                className="save-edit"
                                onClick={send}
                            >
                                Enviar
                            </Button>
                        </div>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default Notifications