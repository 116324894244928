import React, { useState, useCallback, useRef } from 'react'
import "antd/dist/antd.css";
import { Link } from 'react-router-dom';
import {InboxOutlined} from '@ant-design/icons';
import {addActivity, updatePosition, updateActivitiesByPosition} from "../../../api/calls/activity";
import { Table, Tag, Space, Button, Input, message, Switch, Upload } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import Modal from '../../Modal';
import DotsIcon from '../../Icon/DotsIcon';
import PlusIcon from '../../Icon/PlusIcon';
import '../Activities.css';
import {encodingFile, getFileType, uploadTempFile, urlSigned, createFile} from "../../UploadFile/UploadFile";

const TableActivities = ({dataSource, setSource}) => {

    const {Dragger} = Upload;
    const [files , setFiles]   = useState(null);
    const [newImg, setNewImg]  = useState(null);
    const [name  , changeName] = useState(null);
    const [errAct, setError]   = useState(false);
    const [temp_img_url, SetTemp] = useState(null);
    const [disable, disabledBtn] = useState(false);
    const [activeActivity, setActive] =useState(false);
    const [encodingStatus, setEncStatus] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const props = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        customRequest: async options => {
            const { onSuccess, onError, onProgress } = options;
            const uploaded = await uploadTempFile(files.urlSigned, files, onProgress);
            if (uploaded) {
                files.success = true;
                files.url= uploaded;
                // set encoding flag
                setEncStatus("encoding");
                onSuccess("Ok");
                return uploaded;
            }
            else {
                onError({err: uploaded})
                return false;
            }
        },
        accept: "image/*,video/*,audio/*",
        onChange(info) {
            disabledBtn(true)
            const { status } = info.file;
            if (status === 'done') {
                if(files.url){
                    setNewImg(files.url);
                    disabledBtn(false)
                }
            } else if (status === 'error') {
                setEncStatus("errorUploading");
                // print an error on the screen
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        async beforeUpload(file) {
          if (file.type) {
                const urlResponse = await urlSigned(file.type);
                if (urlResponse.status === 200) {
                    file = createFile([file], urlResponse.nameFile, {
                        type: file.type,
                    });
                    file.urlSigned = urlResponse.url;
                }
            }
            setFiles(file);
            return file;
        },
        onRemove() {
            SetTemp(null)
            setEncStatus(null)
            setNewImg(null);
        },
    };

    const type = 'DraggableBodyRow';

    const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
        const ref = useRef();
        const [{ isOver, dropClassName }, drop] = useDrop({
            accept: type,
            collect: monitor => {
                const { index: dragIndex } = monitor.getItem() || {};
                if (dragIndex === index) {
                    return {};
                }
                return {
                    isOver: monitor.isOver(),
                    dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
                };
            },
            drop: item => {
              moveRow(item.index, index);
            },
        });
        const [, drag] = useDrag({
            type,
            item: { index },
            collect: monitor => ({
                isDragging: monitor.isDragging(),
            }),
        });
        drop(drag(ref));

        return (
            <tr
                ref={ref}
                className={`${className}${isOver ? dropClassName : ''}`}
                style={{ cursor: 'move', ...style }}
                {...restProps}
            />
        );
    };

    const columns = [
        {
            title: '',
            dataIndex: 'sort',
            width: 45,
            key: 'sort',
            render: () => <DotsIcon/>,
        },
        {
            title: 'Imagen',
            dataIndex: 'img_url',
            key: 'img_url',
            render: (tags) => {
                return (
                    <img 
                        alt="tableImg" 
                        className="tableImg"
                        src={tags}
                    />
                )
            },
        },
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Activo',
            dataIndex: 'active',
            key: 'active',
            render: tag => {
                const color = tag === true ? 'green' : 'volcano';
                const text = tag ? 'SI' : 'NO'
                return <span>
                    <Tag color={color} key={tag}>
                        {text}
                    </Tag>
                </span>
            },
        },
        {
            title: 'Acciones',
            dataIndex: 'name',
            key: 'action',
            render: (text, record) => (
                <Space key={record} size="middle">
                    <Link to={"/activity?a=" + text.toLowerCase().trim().replace(/\s+/g, '_')+"&k="+record.key}>
                        <Button 
                            type="primary" 
                            size="small"
                        >
                            Editar
                        </Button>
                    </Link>
                    <Link to={"/activity?c=" + text.toLowerCase().trim().replace(/\s+/g, '_')+"&k="+record.key}>
                        <Button 
                            type="primary" 
                            size="small"
                        >
                            Contenido
                        </Button>
                    </Link>
                </Space>
            ),
        },
    ];

    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };

    const moveRow = useCallback(
        async (dragIndex, hoverIndex) => {
            const dragRow = dataSource[dragIndex];
            if(dragIndex !== hoverIndex){
                await updatePosition(dragRow.key, hoverIndex)
                const from = dragIndex > hoverIndex ? hoverIndex : dragIndex + 1;
                const to = dragIndex > hoverIndex ? dragIndex : hoverIndex + 1;
                const moveTo = dragIndex > hoverIndex ? 1 : -1;
                await updateActivitiesByPosition(from, to, dragRow.key, moveTo)
            }
            setSource(
                update(dataSource, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }),
            );
        },
        [dataSource],
    );

    const success = () => {
        message.success({
            content: '¡Nuevo pin guardado!'
        });
    };

    const errorMsg = () => {
        message.error({
            content: 'Hubo un error, por favor intente de nuevo.'
        });
    };

    const onChange = async (checked) =>{
        setActive(checked);
        return false;
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const nameHandler = (e) => {
        changeName(e.target.value)
    }

    const validate = () =>{
        setError(false);
        let error = false;
        let nameField = document.getElementById("nameField");
        nameField.classList.remove("input-error")
        if(!name){
            setError("Nombre es obligatorio");
            nameField.classList.add("input-error");
            error = true;
        }
        if(error){
            return null;
        }else{
            let data = {
                active: activeActivity,
                img_url: newImg,
                name: name,
                key: null,
                position: dataSource.length
            };
            return data;
        }
    }

    const saveActivity = async() => {
        let data = validate();
        if(data){
            let newPin = await addActivity(data);
            if(newPin){
                success()
                changeName('')
                setIsModalVisible(false);    
            } else {
                errorMsg()
            }
        }
    };

    return (
        <div>
            <Button 
                type="primary" 
                onClick={showModal}
                className="new-activity-btn"
            >
                <PlusIcon/>
                Agregar Actividad
            </Button>
            <DndProvider backend={HTML5Backend}>
                <Table
                    dataSource={dataSource} 
                    columns={columns} 
                    pagination={false}
                    className="table-size"
                    components={components}
                    onRow={(record, index) => ({
                        index,
                        moveRow,
                    })}
                />
            </DndProvider>
            <Modal
                handleCancel={handleCancel}
                isModalVisible={isModalVisible}
                title="Nueva Actividad"
                body={
                    <div className="newActivityModal">
                        <div className="field">
                            Nombre
                            <Input
                                maxLength={30}
                                id="nameField"
                                defaultValue={name}
                                placeholder="Nombre"
                                className="edit-name"
                                onChange={nameHandler}
                            />
                        </div>
                        <Dragger
                            {...props}
                            className="dragger-edit"
                        >
                            {newImg || temp_img_url?
                                <img 
                                    src={
                                      encodingStatus==="encoding" && temp_img_url
                                        ? temp_img_url
                                        : newImg
                                    }
                                    alt="preview"
                                    className="preview-newAct"
                                />
                            :
                                <div>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                        Haz click o arrastra una imagen a esta área para subirla.
                                    </p>
                                </div>
                            }
                        </Dragger>
                        Activo
                        <Switch
                            defaultChecked={activeActivity}
                            onChange={onChange}
                            className="switch-edit"
                        />
                        {errAct ?
                            <div className="text-error mapErr">
                                {errAct}
                            </div>
                        : null}
                    </div>
                }
                footer={
                    <Button 
                        key="submit" 
                        type="primary"
                        disabled={disable}
                        className="footer-btn"
                        onClick={saveActivity}
                    >
                        Guardar Cambios
                    </Button>
                }
            />
        </div>
    )
}

export default TableActivities
