import {getFirestore, updateDoc, doc, getDoc, getDocs, addDoc, collection, query, deleteDoc} from "firebase/firestore";
import {initializeApp} from "firebase/app";
import firebaseConfig from "../../config/firebase";

initializeApp(firebaseConfig);

const db = getFirestore();
// use the document places on a custom function to get by key
const placeRef = placeKey => doc(db, "place", placeKey);

/**
 * updatePlace: function to update the place values
 * @param key:String - the document key to update for example: KszB9H60hzUXGdiv21m5
 * @param values: {Object} - the values to update: {active: true}
 * @returns Error<Promise>|undefined - if exist an error if not return undefined
 */
export const updatePlace = async (key, values={}) => {
  return await updateDoc(placeRef(key), values)
    .then(_=>true);
}

/**
 * Add a new document in collection "place"
 * @param values: {Object} - the values to create new doc: {active: true}
 */
 export const addPlace = async (values) => {
    const docRef = await addDoc(collection(db, "place"), values);
    return docRef;
}

/** Get all documents in collection "place" **/
export const getPlaces = async(setPlaces) => {
    const q = query(collection(db, "place"))
    const querySnapshot = await getDocs(q);
    const arrayPlaces = querySnapshot.docs.map(doc => {
        return {...doc.data(), key: doc.id};
    });
    return arrayPlaces;
}

/** 
 * Get a document deleted in collection "place" 
 * @param key:String - the document key to delete for example: KszB9H60hzUXGdiv21m5
 */
export const deletePlace = async (key) => {
    return await deleteDoc(doc(db, "place", key));
}