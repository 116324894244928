import React, { useState, useEffect } from 'react'
import { Layout, Breadcrumb } from 'antd';
import "antd/dist/antd.css";
import './act.css';
import SiderBar from '../../components/Layout/SiderBar';
import TableActivities from '../../components/Activities/TableActivities';

import firestore from '../../api/firebase/firestore';

const Activities = () => {

    const [activity, setActivity] = useState([])

    useEffect(() => {
        firestore.getActivities(setActivity); //listen for activity change
    }, []);
    const { Header, Content } = Layout;

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <SiderBar
                active = {1}
            />
            <Layout
                className="site-layout"
            >
                <Header
                    className="site-layout-background"
                    style={{ padding: 0 }}
                />
                <Content
                    style={{ margin: '0 16px' }}
                >
                    <Breadcrumb
                        style={{ margin: '16px 0' }}
                    >
                        <Breadcrumb.Item>
                            Actividades
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div 
                        className="site-layout-background table-act" 
                        style={{ padding: 24, minHeight: 360 }}
                    >
                        <TableActivities dataSource={activity} setSource={setActivity}/>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default Activities
