import React from 'react';

const PlusIcon= ({className = null})=>{
    return(
        <svg  
            xmlns="http://www.w3.org/2000/svg" 
            width="12"
            height="12"
            viewBox="0 0 12 12"
            className={className}
        >
            <path 
                d="M6.52285 0H5.47634C5.38331 0 5.3368 0.0444444 5.3368 0.133333V5.36667H0.139535C0.0465116 5.36667 0 5.41111 0 5.5V6.5C0 6.58889 0.0465116 6.63333 0.139535 6.63333H5.3368V11.8667C5.3368 11.9556 5.38331 12 5.47634 12H6.52285C6.61587 12 6.66238 11.9556 6.66238 11.8667V6.63333H11.8605C11.9535 6.63333 12 6.58889 12 6.5V5.5C12 5.41111 11.9535 5.36667 11.8605 5.36667H6.66238V0.133333C6.66238 0.0444444 6.61587 0 6.52285 0Z" 
                fill="white" 
                fillOpacity="0.85"
            />
        </svg>
    )
}
export default PlusIcon ;

