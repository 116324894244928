/**
 * Loading firestore hooks
 */
import { orderBy, getFirestore, updateDoc, doc, getDoc, collection, addDoc, where, getDocs, query} from "firebase/firestore";

import {initializeApp} from "firebase/app";
import firebaseConfig from "../../config/firebase";
import Bluebird from "bluebird";
initializeApp(firebaseConfig);

const db = getFirestore();
// use the document activity on a custom function to get by key
export const activityRef = activityKey => doc(db, "activity", activityKey);

/**
 * Add a new document in collection "activity"
 * @param values: {Object} - the values to create new doc: {active: true}
 */
 export const addActivity = async (values) => {
    const newDocRef = await addDoc(collection(db, "activity"), values);
    const updateKey =await updateDoc(newDocRef, {
      key: newDocRef.id
    });
    return true;
}

/**
 * updateActivity: function to update the activity values
 * @param keyOfActivity:String - the document key to update for example: EON0xOa7MUyliXzFdnoX
 * @param values: {Object} - the values to update: {active: true}
 * @returns Error<Promise>|undefined - if exist an error if not return undefined
 */
export const updateActivity = async (keyOfActivity, values={}) => {
  return await updateDoc(activityRef(keyOfActivity), values)
    .then(_=>true);
}

export const getActivity = async (key) => {
  const doc = await getDoc(activityRef(key));
  return doc.data();
}

export const getActivitiesActive = async () => {
  const q = query(collection(db, "activity"), where("active", "==", true), orderBy("position"));
  const querySnapshot = await getDocs(q);
  const activity = await Bluebird.map(querySnapshot.docs, doc => {
    return { ...doc.data(), key: doc.id };
  });
  if (activity.length > 0) return activity;
}

export const updatePosition = async (keyOfActivity, position) => {
  return await updateActivity(keyOfActivity, {position: position})
}

export const updateActivitiesByPosition = async (from, to, ignore, moveTo) => {
  const q = query(
    collection(db, "activity"), 
    where("position", ">=", from), 
    where("position", "<", to), 
    orderBy("position")
  );
  const querySnapshot = await getDocs(q);
  const activity = await Bluebird.map(querySnapshot.docs, async (doc) => {
    if(doc.id !== ignore){
      let data = doc.data()
      return await updateActivity(doc.id, {position: data.position + moveTo});
    }
  });
  return activity;
}