import React from 'react';

const PinIcon = ({className = null})=>{
    return(
        <svg  
            xmlns="http://www.w3.org/2000/svg" 
            width="15"
            height="15"
            viewBox="0 0 15 15"
            className={className}
        >
            <path 
                d="M14.807 5.10873L9.89177 0.193497C9.76211 0.0638341 9.59255 0 9.42299 0C9.25343 0 9.08387 0.0638341 8.95421 0.193497L5.74055 3.40914C5.49719 3.38122 5.25182 3.36925 5.00646 3.36925C3.54625 3.36925 2.08605 3.85 0.887163 4.8115C0.814563 4.8698 0.755058 4.94273 0.712523 5.02555C0.669989 5.10838 0.64538 5.19923 0.640299 5.2922C0.635218 5.38517 0.649778 5.47816 0.683033 5.56513C0.716287 5.6521 0.767489 5.73108 0.833303 5.79694L4.45789 9.42153L0.161049 13.7144C0.108395 13.7667 0.0758989 13.8359 0.0692873 13.9099L0.00146359 14.6519C-0.0164898 14.8394 0.133121 14.999 0.31864 14.999C0.328614 14.999 0.338588 14.999 0.348562 14.997L1.09063 14.9292C1.16444 14.9232 1.23426 14.8893 1.28613 14.8375L5.58296 10.5406L9.20755 14.1652C9.33721 14.2949 9.50677 14.3587 9.67633 14.3587C9.86983 14.3587 10.0613 14.2749 10.193 14.1113C11.3161 12.709 11.7829 10.9535 11.5934 9.25595L14.807 6.0423C15.0643 5.78697 15.0643 5.36805 14.807 5.10873ZM10.576 8.24458L10.0873 8.73331L10.1631 9.41953C10.2819 10.4809 10.0701 11.5531 9.55664 12.4896L2.51294 5.44186C2.77027 5.30023 3.03758 5.18054 3.31685 5.08479C3.85944 4.89728 4.42796 4.80352 5.00646 4.80352C5.19796 4.80352 5.39146 4.8135 5.58296 4.83544L6.26918 4.91124L6.75791 4.42251L9.42498 1.75544L13.2451 5.57552L10.576 8.24458Z" 
                fill="white"
            />
        </svg>
    )
}
export default PinIcon ;

