import React from "react";
import GoogleMapReact from 'google-map-react';

export default function GoogleMap(props){

    const {
        markers
    } = props;
    const defaultProps = {
        center: {
            lat: 23.189060,
            lng: -106.426031
        },
        zoom: 19
        // streetViewControl: false,
        // mapTypeControl: true,
        // mapTypeId: maps.MapTypeId.SATELLITE,
        // mapTypeControlOptions: {
        //     style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        //     position: maps.ControlPosition.BOTTOM_CENTER,
        //     mapTypeIds: [
        //         maps.MapTypeId.ROADMAP,
        //         maps.MapTypeId.SATELLITE,
        //         maps.MapTypeId.HYBRID
        //     ]
        // }
    };

    return (
        <div style={{ height: '70vh', width: '100%' }}>
            <GoogleMapReact
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                bootstrapURLKeys={{
                    key: 'AIzaSyADOXhczFeOorOAHDYJeaiQEH7IpQ4iAGQ',
                    language: 'es',
                    libraries:['places']
                }}
                yesIWantToUseGoogleMapApiInternals={true}
                options={map => ({ mapTypeId: map.MapTypeId.SATELLITE })}
            >
                {markers}
            </GoogleMapReact>
        </div>
    );
}