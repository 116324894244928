import React from 'react';

const MarkerIcon = ({className = null})=>{
    return(
        <svg  
            xmlns="http://www.w3.org/2000/svg" 
            width="20"
            height="26"
            viewBox="0 0 20 26"
            className={className}
        >
            <path 
                d="M9.99995 26C8.19558 24.4441 6.52309 22.7384 4.99998 20.9007C2.71427 18.141 1.26237e-06 14.0309 1.26237e-06 10.1144C-0.00202467 6.02391 2.43463 2.33533 6.17292 0.769889C9.91122 -0.795556 14.2143 0.0706951 17.0742 2.96442C18.9548 4.85713 20.0083 7.43172 20 10.1144C20 14.0309 17.2856 18.141 14.9999 20.9007C13.4768 22.7384 11.8043 24.4441 9.99995 26ZM9.99995 5.78195C8.46882 5.78195 7.054 6.60771 6.28843 7.94817C5.52287 9.28863 5.52287 10.9401 6.28843 12.2806C7.054 13.6211 8.46882 14.4468 9.99995 14.4468C12.3669 14.4468 14.2856 12.5071 14.2856 10.1144C14.2856 7.72165 12.3669 5.78195 9.99995 5.78195Z"
                fill="url(#paint0_linear)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="10"
                    y1="0"
                    x2="10"
                    y2="26"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop 
                        stopColor="#3498DB"
                    />
                    <stop 
                        offset="1"
                        stopColor="#34BDDB"
                        stopOpacity="0.9"
                    />
                </linearGradient>
            </defs>
        </svg>
    )
}
export default MarkerIcon;

