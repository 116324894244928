import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import firebaseConfig from "./config/firebase";

import Map           from './containers/Map';
import Login         from './containers/Login';
import Loading       from './containers/Loading';
import Activities    from './containers/Activities';
import Notifications from './containers/Notifications';
import Activity      from './containers/Activities/Activity';

import './App.css';

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth();

const App = () => {

    const [user, setUser] = useState({});
    const [userAuthenticated, setUserAuthenticated] = useState(false);
    const [firebaseLoaded, setFirebaseLoaded] = useState(false);

    useEffect(() => {
    
        const unregister = onAuthStateChanged(auth, (usr) => {
            if (usr) setUser(usr);
            setUserAuthenticated(!!usr);
            setFirebaseLoaded(true);
        });
        return () => unregister();

    }, []);

    return (
        <Router> 
            {firebaseLoaded === false ? (
            <Switch>
                <Loading />
            </Switch>
            ) : (
            <Switch >
                <PrivateRoute
                    exact
                    path="/"
                    checkAuth={userAuthenticated}
                    component={Activities}
                    redirect="/login"
                />
                <PrivateRoute
                    exact
                    path="/activity"
                    checkAuth={userAuthenticated}
                    component={Activity}
                    redirect="/login"
                />
                <PrivateRoute
                    exact
                    path="/activity/subsection"
                    checkAuth={userAuthenticated}
                    component={Activity}
                    redirect="/login"
                />
                <PrivateRoute
                    exact
                    path="/map"
                    checkAuth={userAuthenticated}
                    component={Map}
                    redirect="/login"
                />
                <PrivateRoute
                    path="/login"
                    checkAuth={!userAuthenticated}
                    component={Login}
                    redirect="/"
                />
                <PrivateRoute
                    path="/notifications"
                    checkAuth={userAuthenticated}
                    component={Notifications}
                    redirect="/login"
                />
            </Switch>
            )}
        </Router>
    )
}

function PrivateRoute({component: Component, checkAuth, redirect, ...rest}) {
    return (
      <Route
        {...rest}
        render={(props) =>
          checkAuth ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: redirect,
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }

export default App
