import {orderBy, where, query, getFirestore, collection, addDoc, onSnapshot} from "firebase/firestore";

async function addLog() {
    const db = getFirestore();
  
    try {
        await addDoc(collection(db, "logs"), {
            timestamp: new Date(),
            message: 'Hola Mundo',
        });
    } catch (e) {
        console.log(e);
    }
};

async function getActivities(setActivity) {
    const db = getFirestore();
    const q = query(collection(db, "activity"), orderBy("position"));
    const unsub = onSnapshot(q, (querySnapshot) => {

        const activity = querySnapshot.docs.map(doc =>{
            return {...doc.data(), key: doc.id};
        });

        if (activity.length > 0) setActivity(activity);
    });
    return unsub;
}

export default {
    addLog,
    getActivities
}