var firebaseConfig = {
  apiKey: "AIzaSyDcrm3Z4Fl416klS0ztvmZhSRfg3qaah_w",
  authDomain: "observatorio1873-dev.firebaseapp.com",
  databaseURL: "https://observatorio1873-dev.firebaseio.com",
  projectId: "observatorio1873-dev",
  storageBucket: "observatorio1873-dev.appspot.com",
  messagingSenderId: "78650306872",
  appId: "1:78650306872:web:412ab50f872d0a410ed1cd",
  measurementId: "G-8HB2PR05T0"
};

export default firebaseConfig;
