import React, { useState, useEffect } from 'react'
import { Button, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "antd/dist/antd.css";
import './Login.css';

import firestore from '../../api/firebase/firestore'

const Login = () => {

    const [state, setState] = useState({
        name: '',
        password: '',
        loginErrClr: false
    });

    const changeHandler = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value
        });
    }

    const keyEnter = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            login();
        }
    }

    const validate = () => {
        setState({
            ...state,
            loginErrClr: false
        });
        let data     = null;
        let error    = false;
        let nameInput= document.getElementById("email");
        let pwdInput = document.getElementById("pwd");
        nameInput.classList.remove("input-error");
        pwdInput.classList.remove("input-error");

        if(!state.email){
            nameInput.focus();
            nameInput.classList.add("input-error");
            error=true;
        }
        if(!state.pwd){
            pwdInput.focus();
            pwdInput.classList.add("input-error");
            error=true;
        }
        if(!error){
            data = {
                user: {
                    email: state.email,
                    password: state.pwd
                }
            };
        }
        return data;
    }

    const login = async () => {
        const data = validate();
        if (!data) return;
        const auth = getAuth();
        signInWithEmailAndPassword(auth, data.user.email, data.user.password).then((userCredential) => {
            // Signed in succesfully
            const user = userCredential.user;

        }).catch((error) => {
            // Error signing in
            const errorCode = error.code;
            const errorMessage = error.message;
            setState({
                ...state,
                loginErrClr: true
            });
        });
    }

    //ESTE ES EL EJEMPLO DE FIRESTORE
    useEffect(() => {
        firestore.addLog()
    }, []);

    return (


        <div
            className="container"
        >
            <div
                className="login-container"
            >
                <img 
                    src="https://observatorio.sfo3.digitaloceanspaces.com/logo_big.png" 
                    alt="logo"
                />
                <div className="forms">
                    <div className="input-login">
                        <UserOutlined />
                        <Input 
                            placeholder="Email"
                            id="email"
                            maxLength={60}
                            onChange={changeHandler}
                            onKeyUp={(e) => keyEnter(e)}
                        />
                    </div>
                    <div className="input-login">
                    <LockOutlined />
                        <Input
                            id="pwd"
                            maxLength={30}
                            onChange={changeHandler}
                            placeholder="Contraseña"
                            type="password"
                            onKeyUp={(e) => keyEnter(e)}
                        />
                    </div>
                </div>
                {state.loginErrClr ? 
                    <div
                        className="text-error"
                    > 
                        Usuario o contraseña incorrecto
                    </div>
                : null }
                <Button 
                    type="primary"
                    onClick={login}
                    className="login-btn"
                >
                    Iniciar sesión
                </Button>
            </div>
        </div>
    )
}

export default Login
