import React, {createContext, useState} from 'react';
//import firestore from "../api/firebase/firestore";
export const ActivityContext = createContext();

export const ActivityProvider = (props) => {
  const {children} = props;

  const [activities] = useState([]);

  return (
    <ActivityContext.Provider
      value={{
        activities
      }}
    >
      {children}
    </ActivityContext.Provider>
  )
}