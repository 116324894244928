import {orderBy, doc, getFirestore, onSnapshot, where, collection, query, getDoc, updateDoc, addDoc, getDocs} from "firebase/firestore";

import {initializeApp} from "firebase/app";
import firebaseConfig from "../../config/firebase";
import Bluebird from "bluebird";

initializeApp(firebaseConfig);

const db = getFirestore();

let activityObjectRef = activityKey => doc(db, "activity_object", activityKey);

/**
 * Add a new document in collection "activity_object"
 * @param values: {Object} - the values to create new doc: {active: true}
 */
 export const addActivity = async (values) => {
    const newObject = await addDoc(collection(db, "activity_object"), values);
    return newObject;
}

/**
 * Update the link of a document in collection "activity_object" to save object key
 * @param values: {Object} - the values to update link doc: {link: string}
 */
export const updateLinkObject = async (keyOfActivity, values={}) => {
  return await updateDoc(activityObjectRef(keyOfActivity), values)
    .then(_=>true);
}

/**
 * Get activity_objects: function to update the activity_object values
 * @param key:string collection key to get for example: EON0xOa7MUyliXzFdnoX
 * @param setActivityObject:useState function - setActivityObject function to update the data
 * @returns Array|[] - returns the data of the collection
 */
export const getActivitiesObject = async (key, setActivityObject) => {
    const activity = doc(db, "activity", key);
    const q = query(collection(db, "activity_object"), where("id_activity", "==", activity), orderBy("position"));
    return onSnapshot(q, querySnapshot  => {
      const activity = querySnapshot.docs.map(doc =>{
        return {...doc.data(), key: doc.id};
      });
      if (activity.length > 0) setActivityObject(activity);
    });
}


export const getActivityObject = async (key, setActivityObj) => {
  const doc = await getDoc(activityObjectRef(key));
  setActivityObj(doc.data());
  return doc.data();
}

export const updateActivityObject = async (keyOfActivity, values={}) => {
  return await updateDoc(activityObjectRef(keyOfActivity), values)
    .then(_=>true);
}

export const updatePosition = async (keyOfActivity, position) => {
  return await updateActivityObject(keyOfActivity, {position: position})
}

export const updateObjectByPosition = async (key, from, to, ignore, moveTo) => {
  const activity = doc(db, "activity", key);
  const q = query(
    collection(db, "activity_object"), 
    where("id_activity", "==", activity),
    where("position", ">=", from), 
    where("position", "<", to), 
    orderBy("position")
  );
  const querySnapshot = await getDocs(q);
  const object = await Bluebird.map(querySnapshot.docs, async (doc) => {
    if(doc.id !== ignore){
      let data = doc.data()
      return await updateActivityObject(doc.id, {position: data.position + moveTo});
    }
  });
  return object;
}
