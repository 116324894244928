import React, { useEffect, useState } from 'react'
import { Layout, Row, Col, Tooltip, Button, Input, Upload, message, Select } from 'antd';
import './map.css';
import "antd/dist/antd.css";
import {Spin} from 'antd';
import {InboxOutlined, CloseOutlined} from '@ant-design/icons';
import {getPlaces, updatePlace, addPlace, deletePlace} from "../../api/calls/places";
import { getActivitiesActive, activityRef } from "../../api/calls/activity";
import {createFile, getFileType, uploadTempFile, urlSigned, encodingFile} from "../../components/UploadFile/UploadFile";
import Modal from '../../components/Modal';
import GoogleMap from '../../components/Map';
import PinIcon from '../../components/Icon/PinIcon';
import EditIcon from '../../components/Icon/EditIcon';
import SiderBar from '../../components/Layout/SiderBar';
import MarkerIcon from '../../components/Icon/MarkerIcon';
import MediaRender from "../../components/Activities/Subsection/MediaRender/MediaRender";
const { Option } = Select;
const Map = () => {

    const props = {
        id: 'dragger-id',
        name: 'file',
        multiple: false,
        maxCount: 1,
        customRequest: async options => {
            const { onSuccess, onError, onProgress } = options;
            const uploaded = await uploadTempFile(files.urlSigned, files, onProgress);
            if (uploaded) {
                files.success = true;
                files.url= uploaded;
                // set encoding flag
                changeImageEdit(files.url);
                if (indexNumber === undefined || indexNumber === null) {
                    places.push({
                        encodingStatus: "encoding"
                    });
                    setIndexNumber(places.length-1);
                } else {
                    places[indexNumber].encodingStatus="encoding";
                }
                savePlaces(places);
                onSuccess("Ok");
                return uploaded;
            }
            else {
                onError({err: uploaded})
                return false;
            }
        },
        accept: "image/*",
        async onChange(info) {
            const placesCopy = [...places];
            const { status, type } = info.file;
            if (status === 'done') {
                if(files.url){
                    const urlResponse = await getFileType(type);
                    if (urlResponse === "image"){
                        placesCopy[indexNumber].temp_img_url = files.url;
                    }
                    savePlaces(placesCopy);
                }
            } else if (status === 'error') {
                placesCopy.encodingStatus = "errorUploading";
                savePlaces(placesCopy);
                // print an error on the screen
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        async beforeUpload(file) {
            if (file.type) {
                const urlResponse = await urlSigned(file.type);
                if (urlResponse.status === 200) {
                    file = createFile([file], urlResponse.nameFile, {
                        type: file.type,
                    });
                    file.urlSigned = urlResponse.url;
                }
            }
            setFiles(file);
            return file;
        },
        onRemove() {
            const placesCopy = [...places];
            delete placesCopy[indexNumber].encodingStatus;
            delete placesCopy[indexNumber].temp_img_url;
            savePlaces(placesCopy);
            changeImageEdit(null)
        },
    };

    const { TextArea } = Input;
    const {Dragger} = Upload;
    const {Header, Content} = Layout;
    const numberRegex = /^[0-9.-]+$/;
    const textTool = <span>Nuevo Pin</span>;
    const Marker = ({ text }) => <div className="pin-site"><MarkerIcon/>{text}</div>;
    const [pins          , getMarkers       ] = useState([]);
    const [items         , itemList         ] = useState([]);
    const [itemActivity  , activityList     ] = useState([]);
    const [places        , savePlaces       ] = useState([]);
    const [placeObj      , setPlaceObj      ] = useState({});
    const [selectedCoords, changeCoordsEdit ] = useState([]);
    const [selected      , selectTypeActivity] = useState(0);
    const [files         , setFiles         ] = useState(null);
    const [selectedPin   , changePinEdit    ] = useState(null);
    const [selectedName  , changeNameEdit   ] = useState(null);
    const [img           , changeImageEdit  ] = useState(null);
    const [errMap        , setError         ] = useState(false);
    const [loading       , loadingEvent     ] = useState(false);
    const [indexNumber   , setIndexNumber   ] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedDescription , changeDescriptionEdit  ] = useState(null);
    const [isModalDeleteVisible, setIsModaDeletelVisible] = useState(false);

    useEffect(() => {
        allPlaces();
        // eslint-disable-next-line
    }, []);

    const allPlaces = () => {
        getPlaces().then( res => {
            allcoordinates(res);
            savePlaces(res);
            markers(res);
        })
    }

    useEffect(()=>{
        allActivities();
        // eslint-disable-next-line
    }, []);

    const allActivities = () => {
        getActivitiesActive()
            .then(res => {
                renderSelectActivities(res);
            })
    }

    const success = (mensaje) => {
        message.success({
            content: mensaje,
            style: {
              marginTop: '15px',
            },
        });
    };

    const markers = async(placesPins) => {
        let array = [];
        placesPins.map((el, index) => {
            return array.push(
                <Marker
                    key={index}
                    lat={el.coords._latitude}
                    lng={el.coords._longitude}
                    text={el.name}
                />
            )
        })
        getMarkers(array)
    }

    const changeName = async e => {
        changeNameEdit(e.target.value)
        setPlaceObj({...places[indexNumber], name: e.target.value});
    }

    const changeDescription = async e => {
        changeDescriptionEdit(e.target.value)
        setPlaceObj({...places[indexNumber], description: e.target.value});
    }

    const changeCoordsLat = async e => {
        let value = e.target.value;
        let longitude = selectedCoords ? selectedCoords[1] : null;
        if(!numberRegex.test(value)){
            value = value.replace(/[^0-9.-]/g,'')
        }
        changeCoordsEdit([value, longitude]);
        setPlaceObj({...places[indexNumber], coords: {_latitude: value, _longitude: longitude}});
    }

    const changeCoordsLong = async e => {
        let value = e.target.value;
        let latitude = selectedCoords ? selectedCoords[0] : null;
        if(!numberRegex.test(value)){
            value = value.replace(/[^0-9.-]/g, '')
        }
        changeCoordsEdit([latitude, value]);
        setPlaceObj({...places[indexNumber], coords: {_latitude: latitude, _longitude: value}});
    }

    const ShowModalDelete = (index = null, id = null, name = null) =>{
        changePinEdit(id)
        changeNameEdit(name ? name : null);
        setIsModaDeletelVisible(true);
    }

    const deletePin = async(key) =>{
        await deletePlace(key);
        allPlaces();
        setIsModaDeletelVisible(false)
        success('¡Pin eliminado correctamente!')
    }

    const showModal = (index = null, id = null , name = null, coords = null, description=null, image=null) => {
        setIndexNumber(index)
        changePinEdit(id)
        changeNameEdit(name ? name : null);
        changeCoordsEdit(coords ? [coords._latitude,coords._longitude] : null);
        changeDescriptionEdit(description);
        changeImageEdit(image);
        setFiles(null);
        setIsModalVisible(true);
    };

    const validate = () =>{
        setError(false);
        let error = false;
        let nameField = document.getElementById("nameField");
        let latField  = document.getElementById("latField");
        let longField = document.getElementById("longField");
        let draggerId = document.getElementById("dragger-id").parentNode.parentNode;
        nameField.classList.remove("input-error")
        latField.classList.remove("input-error")
        longField.classList.remove("input-error")
        draggerId.classList.remove("input-error")
        if(!selectedName){
            setError("Nombre es obligatorio");
            nameField.classList.add("input-error");
            error = true;
        }
        if(!selectedCoords || !selectedCoords[0] || !selectedCoords[1]){
            setError("Las coordenadas son obligatorias");
            latField.classList.add("input-error")
            longField.classList.add("input-error")
            error = true;
        }
        if(!img){
            setError("La imagen es obligatoria");
            draggerId.classList.add("input-error")
            error = true;
        }
        if(error){
            loadingEvent(false);
            return null;
        }else{
            let id_activity = null;
            if (selected !=="none") {
                id_activity = activityRef(selected);
            }
            let data = {
                active: true,
                coords:{
                    _latitude: parseFloat(selectedCoords[0]),
                    _longitude: parseFloat(selectedCoords[1])
                },
                description: selectedName,
                id: null,
                id_activity,
                temp_img_url: img,
                name: selectedName
            };
            return data;
        }
    }

    const saveEdit = async() => {
        loadingEvent(true);

        const placeChange = {...places[indexNumber]};
        placeChange.name = selectedName;
        placeChange.description = selectedDescription;
        placeChange.coords= {_latitude: selectedCoords[0], _longitude: selectedCoords[1]};

        places[indexNumber] = placeChange;

        const key = placeChange.key;
        delete placeChange.key;
        await updatePlace(key, placeChange);
        placeChange.key = key;
        savePlaces(places);
        // esto esta medio raro se puede mejorar pero para no mover mucho codigo asi lo deje
        allcoordinates(places);
        markers(places);
        loadingEvent(false);
        setIsModalVisible(false);
        await encoding();
        success('¡Pin editado!')
    };

    const saveNewPin = async() => {
        loadingEvent(true);
        let data = validate();
        if(data){
            let newPin = await addPlace(data);
            if(newPin && newPin._key.path.segments){
                data.key = newPin.id;
                if (indexNumber === undefined || indexNumber === null) {
                    places.push(data);
                } else {
                    if (places[indexNumber].encodingStatus) {
                        data.encodingStatus = places[indexNumber].encodingStatus;
                    }
                    places[indexNumber] = data;
                }
                savePlaces(places);
                allcoordinates(places);
                markers(places);
                loadingEvent(false);
                setIsModalVisible(false);
                await encoding();
                success('¡Nuevo pin guardado!')
            } else {
                loadingEvent(false);
            }
        }
    };

    const encoding = async () =>{
        if (places[indexNumber].encodingStatus==="encoding" && places[indexNumber].temp_img_url) {
            const trancodingResponse = await encodingFile(
              getFileType(files.type),
              places[indexNumber].temp_img_url,
              places[indexNumber].key,
              {places: true},
            );
            if (trancodingResponse === "No file allow"){
                delete places[indexNumber].temp_img_url;
                delete places[indexNumber].encodingStatus;
                savePlaces(places);
                // print an error on the screen
            }
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsModaDeletelVisible(false);
    };

    const handleChangeSelect = (value) => {
        selectTypeActivity(value);
    }

    const allcoordinates = async (res) => {
        if(res){
            let showCoordinates = [];
            res.map((el, index) => {
                return showCoordinates.push(
                    <li
                        key={index}
                    >
                        <b>{el.name}</b>
                        {el.coords._latitude}, {el.coords._longitude}
                        <Button
                            className="edit"
                            onClick={() => showModal(index, el.key, el.name, el.coords, el.description, el.img_url)}
                        >
                            <EditIcon
                                id={el.id}
                            />
                        </Button>
                        <Button
                            className="delete"
                            onClick={() => ShowModalDelete(index, el.key, el.name)}
                        >
                            <CloseOutlined
                                id={el.id}
                            />
                        </Button>
                    </li>
                )
            })
            itemList(showCoordinates)
        }
    }

    const renderSelectActivities = async (actvities) => {
        try {
            if (!actvities.length) return null;
            let itemAct = [];
            actvities.map((item,index) => {
                return itemAct.push(
                    <Option 
                        value={item.key}
                        key={index}
                    >
                        {item.name}
                    </Option>
                );
            });
            activityList(itemAct);
        }
        catch(e){
            activityList(null);
        }
    }


    return (
        <Layout style={{ minHeight: '100vh' }}>
            <SiderBar
                active = {2}
            />
            <Layout
                className="site-layout"
            >
                <Header
                    className="site-layout-background"
                    style={{ padding: 0 }}
                />
                <Content
                    style={{ margin: '0 16px' }}
                >
                    <Row className="map-container">
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <GoogleMap
                                markers={pins}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <div className="coordinates">
                                <h4>
                                    Puntos de Interés
                                </h4>
                                <div className="subtitle">
                                    Coordenadas GPS
                                </div>
                                <ul>
                                    {items}
                                </ul>
                                <Tooltip
                                    placement="left"
                                    title={textTool}
                                >
                                    <Button
                                        id={4}
                                        onClick={() => showModal()}
                                        className="pin"
                                    >
                                        <PinIcon/>
                                    </Button>
                                </Tooltip>
                            </div>
                        </Col>
                    </Row>
                    <Modal
                        handleCancel={handleCancel}
                        isModalVisible={isModalVisible}
                        title={selectedPin
                            ? "Editar Pin"
                            : "Nuevo Pin"}
                        body={
                            <div className="pin-modal">
                                <div className="field">
                                    Nombre
                                    <Input
                                        maxLength={30}
                                        id="nameField"
                                        value={selectedName}
                                        onChange={changeName}
                                        placeholder="Nombre"
                                        className="edit-name"
                                    />
                                </div>
                                <div className="field">
                                    Coordenadas
                                    <Input
                                        id="latField"
                                        value={selectedCoords
                                            ? selectedCoords[0]
                                            : null}
                                        onChange={changeCoordsLat}
                                        maxLength={25}
                                        className="edit-coord"
                                        placeholder="Latitud"
                                    />
                                    <Input
                                        id="longField"
                                        value={selectedCoords
                                            ? selectedCoords[1]
                                            : null}
                                        onChange={changeCoordsLong}
                                        maxLength={25}
                                        className="edit-coord"
                                        placeholder="Longitud"
                                    />
                                </div>
                                {!selectedPin &&
                                    <div className="field">
                                        Activity
                                        <Select
                                            defaultValue="none"
                                            onChange={handleChangeSelect}
                                            style={{ width: 120 }}
                                        >
                                           <Option value="none">none</Option>
                                            {itemActivity}
                                        </Select>
                                    </div>
                                }
                                <p className="desc-t">
                                    Descripción
                                </p>
                                <TextArea
                                    defaultValue={selectedDescription}
                                    value={selectedDescription}
                                    placeholder="Escribir descripción"
                                    autoSize={{ minRows: 5, maxRows: 5 }}
                                    className="description"
                                    maxLength={1000}
                                    onChange={changeDescription}
                                />
                                <Dragger
                                    {...props}
                                    className="dragger-edit"
                                >
                                    {img ?
                                        <MediaRender
                                            activityObj={{
                                                img_url:img,
                                                imageStyle: {
                                                    width: '50%',
                                                    margin: 20,
                                                }
                                            }}
                                        />
                                    :
                                        <div>
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">
                                                Haz click o arrastra una imagen a esta área para subirla.
                                            </p>
                                        </div>
                                    }
                                </Dragger>
                                {errMap ?
                                    <div className="text-error mapErr">
                                        {errMap}
                                    </div>
                                : ""}
                            </div>
                        }
                        footer={
                            <Spin spinning={loading}>
                                <Button
                                    key="submit"
                                    type="primary"
                                    className="footer-btn"
                                    onClick={selectedPin
                                        ? saveEdit
                                        : saveNewPin
                                    }
                                >
                                    Guardar Cambios
                                </Button>
                            </Spin>
                        }
                    />
                     <Modal
                        handleCancel={handleCancel}
                        isModalVisible={isModalDeleteVisible}
                        title="Eliminar Pin"
                        body={
                            <div className="pin-modal">
                                ¿Desea eliminar el marcador <b>{selectedName}</b> del mapa?
                            </div>
                        }
                        footer={
                            <Spin spinning={loading}>
                                <Button
                                    key="submit"
                                    type="primary"
                                    className="footer-btn"
                                    onClick={() => deletePin(selectedPin)}
                                >
                                    Sí, eliminar pin
                                </Button>
                            </Spin>
                        }
                    />
                </Content>
            </Layout>
        </Layout>
    )
}

export default Map
