import React from 'react';
import { Modal } from 'antd';
import './style.css';

export default function DefaultModal (props) {
    const {
        body,
        title,
        footer,
        handleCancel,
        isModalVisible
    } = props;

    return(
        <Modal
            visible={isModalVisible}
            onCancel={handleCancel}
            className="modal-box"
            title={title}
            footer={footer}
        >
            {body}
        </Modal>
    )
}