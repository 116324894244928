import React, {useEffect, useState, useRef, useCallback} from 'react'
import '../Activities.css';
import "antd/dist/antd.css";
import { Link } from 'react-router-dom';
import {Button, Table, Tag, Input, Upload, Switch, message, Space, Select} from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import Modal from '../../Modal';
import {InboxOutlined, PlusOutlined, LoadingOutlined} from '@ant-design/icons';
import getParams from "../../SearchParams/SearchParams";
import DotsIcon from '../../Icon/DotsIcon';
import PlusIcon from '../../Icon/PlusIcon';
import VideoIcon from '../../Icon/VideoIcon';
import ImageIcon from '../../Icon/ImageIcon';
import AudioIcon from '../../Icon/AudioIcon';
import {getActivitiesObject, addActivity, updateLinkObject, updatePosition, updateObjectByPosition} from "../../../api/calls/activity_object";
import { activityRef } from "../../../api/calls/activity";
import {encodingFile, getFileType, uploadTempFile, urlSigned, createFile} from "../../UploadFile/UploadFile";
import QRCode from 'qrcode'
import { jsPDF } from "jspdf";

const ActivityContent = ({nameSection, getSubsection, contentSection, currSection, keySection}) => {
    const canvasRef = useRef();
    const getParamsEvent = () =>{
        setTimeout( () => {
            const params = getParams();
            if(params.s){
                getSubsection(params.s)
                currSection(null)
            }
        }, 100);
    }
    const {Option} = Select;
    const {Dragger} = Upload;
    const {TextArea} = Input;
    const [files , setFiles]  = useState(null);
    const [newMedia, setNewMedia] = useState(null);
    const [name  , changeName] = useState(null);
    const [errAct, setError] = useState(false);
    const [temp_img_url, SetTemp] = useState(null);
    const [selected, selectTypeMedia] = useState(0);
    const [activeActivity, setActive] = useState(false);
    const [dataSource, setActivityObject] = useState([])
    const [encodingStatus, setEncStatus] = useState(null);
    const [newDescription, setDescription]= useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileAudio, setAudioFile] = useState();
    const [loading , loaderAudio] = useState(null);
    const [audioUrl , setAudioUrl] = useState(null);
    const [disable, disabledBtn] = useState(false);
    const props = {
        id: 'dragger-id',
        name: 'file',
        multiple: false,
        maxCount: 1,
        customRequest: async options => {
            const { onSuccess, onError, onProgress } = options;
            const uploaded = await uploadTempFile(files.urlSigned, files, onProgress);
            if (uploaded) {
                files.success = true;
                files.url= uploaded;
                // set encoding flag
                setEncStatus("encoding");
                onSuccess("Ok");
                return uploaded;
            }
            else {
                onError({err: uploaded})
                return false;
            }
        },
        accept: "image/*,video/*,audio/*",
        onChange(info) {
            disabledBtn(true)
            const { status } = info.file;
            if (status === 'done') {
                if(files.url){
                    let type = getFileType(info.file.type);
                    if (type==="audio") {
                        setAudioUrl(files.url);
                    }
                    else {
                        setNewMedia(files.url);
                    }
                    disabledBtn(false)
                }
            } else if (status === 'error') {
                setEncStatus("errorUploading");
                // print an error on the screen
                message.error(`${info.file.name} file upload failed.`);
            }

        },
        async beforeUpload(file) {
            if (file.type) {
                const urlResponse = await urlSigned(file.type);
                if (urlResponse.status === 200) {
                    file = createFile([file], urlResponse.nameFile, {
                        type: file.type,
                    });
                    file.urlSigned = urlResponse.url;
                }
            }
            setFiles(file);
            return file;
        },
        onRemove() {
            SetTemp(null)
            setEncStatus(null)
            setNewMedia(null);
        },
    };

    useEffect(() => {
        const params = getParams();
        if (params.k)
            getActivitiesObject(params.k, setActivityObject);

    }, []);

    const type = 'DraggableBodyRow';

    const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
        const ref = useRef();
        const [{ isOver, dropClassName }, drop] = useDrop({
            accept: type,
            collect: monitor => {
                const { index: dragIndex } = monitor.getItem() || {};
                if (dragIndex === index) {
                    return {};
                }
                return {
                    isOver: monitor.isOver(),
                    dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
                };
            },
            drop: item => {
              moveRow(item.index, index);
            },
        });
        const [, drag] = useDrag({
            type,
            item: { index },
            collect: monitor => ({
                isDragging: monitor.isDragging(),
            }),
        });
        drop(drag(ref));

        return (
            <tr
                ref={ref}
                className={`${className}${isOver ? dropClassName : ''}`}
                style={{ cursor: 'move', ...style }}
                {...restProps}
            />
        );
    };

    const columns = [
        {
            title: '',
            dataIndex: 'sort',
            width: 45,
            key: 'sort',
            render: () => <DotsIcon/>,
        },
        {
            title: 'Imagen',
            dataIndex: 'img_url',
            key: 'img_url',
            render: (tags) => {
                return (
                    <img
                        alt="tableImg"
                        className="tableImg"
                        src={tags ? tags : "https://observatorio.sfo3.digitaloceanspaces.com/logo_big.png"}
                    />
                )
            },
        },
        {
            title: 'Nombre',
            dataIndex: [],
            key: 'name',
            render: tags => <div className="icon-name">
                {tags.video_url ?
                    <VideoIcon/>
                : tags.audio_url ?
                    <AudioIcon/>
                : <ImageIcon/>}
                {tags.name}
            </div>,
        },
        {
            title: 'Activo',
            dataIndex: 'active',
            key: 'active',
            render: tag => {
                const color = tag === true ? 'green' : 'volcano';
                const text = tag ? 'SI' : 'NO'
                return <span>
                    <Tag color={color} key={tag}>
                        {text}
                    </Tag>
                </span>
            },
        },
        {
            title: 'Acciones',
            dataIndex: 'name',
            key: 'action',
            render: (text, record) => (
                <Space key={record} size="middle">
                    <Link to={"/activity/subsection?s=" + text.toLowerCase().trim().replace(/\s+/g, '_')+"&k="+record.key+"&c="+nameSection}>
                        <Button
                            onClick={()=>{getParamsEvent()}}
                            type="primary"
                            size="small"
                        >
                            Editar
                        </Button>
                    </Link>
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => makeQR(record.link, text)}
                    >
                        Descargar QR
                    </Button>
                </Space>
            ),
        },
    ];

    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };

    const moveRow = useCallback(
        async (dragIndex, hoverIndex) => {
            const dragRow = dataSource[dragIndex];
            if(dragIndex !== hoverIndex){
                await updatePosition(dragRow.key, hoverIndex)
                const from = dragIndex > hoverIndex ? hoverIndex : dragIndex + 1;
                const to = dragIndex > hoverIndex ? dragIndex : hoverIndex + 1;
                const moveTo = dragIndex > hoverIndex ? 1 : -1;
                await updateObjectByPosition(keySection, from, to, dragRow.key, moveTo)
            }
            setActivityObject(
                update(dataSource, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }),
            );
        },
        [dataSource],
    );

    const makeQR = (record, name) => {
        var canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
        QRCode.toCanvas(canvas, record, function (error) {
            if (error) console.error(error)
            var imgData = canvas.toDataURL("image/jpeg", 1.0);
            var pdf = new jsPDF();

            pdf.addImage(imgData, 'JPEG', 0, 0);
            pdf.save(name + "-qr.pdf");
        })
    }

    const success = () => {
        message.success({
            content: '¡Nuevo objeto guardado!'
        });
    };

    const errorMsg = () => {
        message.error({
            content: 'Hubo un error, por favor intente de nuevo.'
        });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const nameHandler = (e) => {
        changeName(e.target.value)
    }

    const descriptionHandler= (e) =>{
        setDescription(e.target.value)
    }

    const onChange = async (checked) =>{
        setActive(checked);
        return false;
    }

    const handleChangeSelect = (value) => {
        selectTypeMedia(value);
    }

    const validate = () =>{
        setError(false);
        let error = false;
        let data = null;
        let nameField = document.getElementById("nameField");
        let selectorField = document.getElementsByClassName("ant-select-selector")[0];
        nameField.classList.remove("input-error")
        selectorField.classList.remove("input-error")
        if(selected === 0){
            setError("Tiene que seleccionar tipo de multimedia");
            selectorField.classList.add("input-error")
            error = true;
        } else {
            let draggerId = document.getElementById("dragger-id").parentNode.parentNode;
            draggerId.classList.remove("input-error")
            if(!newMedia){
                setError("La multimedia es obligatoria");
                draggerId.classList.add("input-error")
                error = true;
            }
        }
        if(!name){
            setError("Nombre es obligatorio");
            nameField.classList.add("input-error");
            error = true;
        }



        if(error){
            return null;
        }else{
            if(selected === 1 ){
                data = {
                    active: activeActivity,
                    description: newDescription,
                    id_activity: activityRef(keySection),
                    temp_img_url: newMedia,
                    link:'observatorio1873://gallery/'+keySection+'/',
                    name: name,
                    ratio: 1,
                    position: dataSource === 0 ? 1 : dataSource.length
                };
            } else if (selected === 2) {
                data = {
                    active: activeActivity,
                    description: newDescription,
                    id_activity:  activityRef(keySection),
                    link:'observatorio1873://gallery/'+keySection+'/',
                    name: name,
                    ratio: 1,
                    position: dataSource === 0 ? 1 : dataSource.length,
                    temp_video_url: newMedia

                };
            } else if (selected === 3) {
                data = {
                    active: activeActivity,
                    temp_audio_url: audioUrl,
                    description: newDescription,
                    id_activity:  activityRef(keySection),
                    temp_img_url: newMedia,
                    link:'observatorio1873://gallery/'+keySection+'/',
                    name: name,
                    ratio: 1,
                    position: dataSource === 0 ? 1 : dataSource.length
                };
            }
            return data;
        }
    }

    const saveActivity = async() => {
        let data = validate();
        debugger;
        if(data){
            let newObjectAct = await addActivity(data);
            if(newObjectAct){
                data.key=newObjectAct.id;
                data.link = data.link +newObjectAct.id;
                let updateObjKey = await updateLinkObject(newObjectAct.id, {link : data.link})
                if(updateObjKey){
                    saveActivityObject(data);
                    success()
                    changeName('')
                    setDescription('')
                    selectTypeMedia(0)
                    setNewMedia(null)
                    SetTemp(null)
                    setAudioUrl(null)
                    setIsModalVisible(false);
                    setActive(false)
                }

            } else {
                errorMsg()
            }
        }
    };
    const saveActivityObject = async (activityObj) =>{
      if (activityObj.key) {
        debugger;
        // if are an file on encoding process, send the encoding trigger to the server
        let activityTempFile = activityObj.temp_img_url || activityObj.temp_video_url;
        if (encodingStatus==="encoding" && (activityTempFile)) {

            const trancodingResponse = await encodingFile(
                selected ===3 ? "image": getFileType(files.type) ,
                activityTempFile,
                activityObj.key,
                { activityObject: true },
            );
            if (trancodingResponse === "No file allow") {
                delete activityObj.temp_img_url;
                delete activityObj.temp_video_url;
                delete activityObj.encodingStatus;
                delete activityObj.temp_audio_url;
                // print an error on the screen
            }

            if (activityObj.temp_audio_url) {
                const trancodingResponseAudio = await encodingFile(
                    "audio",
                    activityObj.temp_audio_url,
                    activityObj.key,
                    { activityObject: true },
                );
                if (trancodingResponseAudio === "No file allow") {
                    delete activityObj.temp_img_url;
                    delete activityObj.temp_video_url;
                    delete activityObj.encodingStatus;
                    delete activityObj.temp_audio_url;
                    // print an error on the screen
                }
            }
        }
        delete activityObj.key;
      }
    }

    const getBase64 = (img, callback) =>{
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleChangeAudio = ({ info }) => {
        if (info.file.status === 'uploading') {
            loaderAudio(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, audioUrl =>{
                setAudioUrl(audioUrl)
                loaderAudio(false)
            })
        }
    };

    return (
        <div>
            <Button
                type="primary"
                className="new-activity-btn"
                onClick={showModal}
            >
                <PlusIcon/>
                Agregar Objeto
            </Button>
            <h4 className="section-title">Contenido</h4>
            <DndProvider backend={HTML5Backend}>
                <Table
                    dataSource={dataSource} 
                    columns={columns} 
                    pagination={false}
                    className="table-size"
                    components={components}
                    onRow={(record, index) => ({
                        index,
                        moveRow,
                    })}
                />
                
            </DndProvider>
            <canvas ref={canvasRef} className="canvasQR"></canvas>
            <Modal
                handleCancel={handleCancel}
                isModalVisible={isModalVisible}
                title="Nuevo Objeto"
                body={
                    <div className="newActivityModal">
                        <div className="field">
                            Nombre
                            <Input
                                maxLength={30}
                                id="nameField"
                                defaultValue={name}
                                value={name}
                                placeholder="Nombre"
                                className="edit-name"
                                onChange={nameHandler}
                            />
                        </div>
                        <h5 className="desc-title">Descripción</h5>
                        <TextArea
                            placeholder="Escribir descripción"
                            autoSize={{ minRows: 5, maxRows: 5 }}
                            className="description"
                            maxLength={1000}
                            value={newDescription}
                            onChange={descriptionHandler}
                        />
                        <div className="field">
                            Tipo de multimedia a subir
                            <Select
                                defaultValue={0}
                                value={selected}
                                onChange={handleChangeSelect}
                                id="selectorField"
                            >
                                <Option value={0}>Seleccionar</Option>
                                <Option value={1}>Imagen</Option>
                                <Option value={2}>Video</Option>
                                <Option value={3}>Audio</Option>
                            </Select>
                        </div>
                        {selected === 0 ?
                            null
                        :
                            <div>
                                <Dragger
                                    {...props}
                                    className="dragger-edit"
                                >
                                    {newMedia || temp_img_url ?
                                        (selected === 1 || selected ===3  ?
                                            <img
                                                src={
                                                  encodingStatus==="encoding" && temp_img_url
                                                    ? temp_img_url
                                                    : newMedia
                                                }
                                                alt="img"
                                                className="preview-newAct"
                                            />
                                        : selected === 2 ?
                                            <video
                                                src={newMedia}
                                                alt="video"
                                                className="preview-newAct"
                                            ></video>
                                        : null
                                        )
                                    :
                                        <div>
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">
                                                Haz click o arrastra una imagen a esta área para subirla.
                                            </p>
                                        </div>
                                    }
                                </Dragger>
                                {selected === 3 ?
                                    <Upload
                                        {...props}
                                        listType="picture-card"
                                        showUploadList={false}
                                        className="avatar-uploader"
                                        accept="audio/*"
                                    >
                                        {audioUrl ?
                                            <audio
                                                src={audioUrl}
                                                autoplay
                                                controls={true}
                                            >
                                                Your browser does not support the <code>audio</code> element.
                                            </audio>
                                        :
                                            <div>
                                                {loading
                                                    ? <LoadingOutlined />
                                                    : <PlusOutlined />
                                                }
                                                <div>
                                                    Añadir audio
                                                </div>
                                            </div>
                                    }
                                    </Upload>
                                : null}
                            </div>
                        }
                        Activo
                        <Switch
                            defaultChecked={activeActivity}
                            onChange={onChange}
                            checked={activeActivity}
                            className="switch-edit"
                        />
                        {errAct ?
                            <div className="text-error mapErr">
                                {errAct}
                            </div>
                        : null}
                    </div>
                }
                footer={
                    <Button
                        key="submit"
                        type="primary"
                        className="footer-btn"
                        onClick={saveActivity}
                        disabled={disable}
                    >
                        Guardar Cambios
                    </Button>
                }
            />
        </div>
    )
}

export default ActivityContent
