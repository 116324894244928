import React from 'react';

const MapIcon= ({color, className = null})=>{
    return(
        <svg  
            xmlns="http://www.w3.org/2000/svg" 
            width="14"
            height="15"
            viewBox="0 0 14 15"
            className={className}
        >
			<path 
				d="M4.9756 15.032L0 13.3723V2.0967L4.8244 3.7067L9.7461 0.891998L14 2.5937V13.9015L9.8539 12.2425L4.9763 15.032H4.9756ZM1.4 4.042V12.3629L4.2 13.296V4.9716L1.4 4.042ZM8.4 3.272L5.6 4.8736V13.0636L8.4 11.462V3.272ZM9.8539 2.4418L9.8 2.474V10.7137L12.6 11.8337V3.5408L9.8539 2.4418Z" 
				fill={color}
			/>
		</svg>
    )
}
export default MapIcon ;

