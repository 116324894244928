import axios from "axios";

const aceptedFiles = ['image', 'audio', 'video'];

export const uploadTempFile = (url, file, onProgress) => {
  try {
    return axios.put(url, file, {
      onUploadProgress: progressEvent => {
        onProgress({ percent: (progressEvent.loaded / progressEvent.total) * 100});
      },
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read'
      }
    })
      .then(async (resp) => {
        if (resp.status===200){
          const urlFile = new URL(url);
          return urlFile.origin + urlFile.pathname;
        }
        return false;
      })
      .catch((err) => {
        return err;
      });
  } catch (e) {
    return e;
  }
}

export const urlSigned = (mimetype) => {
  return axios.get('https://us-central1-observatorio1873-dev.cloudfunctions.net/do-file/signed/url?mimetype='+mimetype)
    .then(resp => resp.data);

}

export const encodingFile = (fileType, URL, fileId, params) => {
  if (!fileType || (fileType && aceptedFiles.indexOf(fileType)===-1)) return "No file allow";

  return axios.post(`https://us-central1-observatorio1873-dev.cloudfunctions.net/api-transcoding/${fileType}/encoding`, {
    fileURL: URL,
    id: fileId,
    cdn: true,
    noBlur: true,
    customParams: params
  })
    .then((resp)=> resp.data);
}

export const createFile = (bits, name, options) => {
  try {
    // If this fails, we go for Blob
    return new File(bits, name, options);
  } catch (e) {
    // If we're here a new File could not be constructed
    var myBlob = new Blob(bits, options || {});
    myBlob.lastModified = new Date();
    myBlob.name = name;
    return myBlob;
  }
};

export const getFileType = (format) => format.split('/')[0];
