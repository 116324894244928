import React, {useEffect, useState} from 'react'
import '../Activities.css';
import "antd/dist/antd.css";
import {Button, Col, Input, message, Row, Switch, Upload} from 'antd';
import {InboxOutlined} from '@ant-design/icons';
import {getActivity, updateActivity} from "../../../api/calls/activity";
import getParams from "../../SearchParams/SearchParams";
import Loading from "../../../containers/Loading";
import {encodingFile, getFileType, uploadTempFile, urlSigned, createFile} from "../../UploadFile/UploadFile";

const ActivityContent = () => {
    const {Dragger} = Upload;
    const props = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        customRequest: async options => {
          const { onSuccess, onError, onProgress } = options;
          const uploaded = await uploadTempFile(files.urlSigned, files, onProgress);
          if (uploaded) {
            files.success = true;
            files.url= uploaded;
            // set encoding flag
            currentActivity.encodingStatus="encoding";
            setActivity(currentActivity);
            onSuccess("Ok");
            return uploaded;
          }
            else {
                onError({err: uploaded})
                return false;
            }
        },
        accept: "image/*,video/*,audio/*",
        onChange(info) {
            const currentActivityCopy = {...currentActivity};
            const { status } = info.file;
            if (status === 'done') {
                if(files.url){
                    currentActivityCopy.temp_img_url = files.url;
                    setActivity(currentActivityCopy);
                }
            } else if (status === 'error') {
                currentActivityCopy.encodingStatus = "errorUploading";
                setActivity(currentActivityCopy);
                // print an error on the screen
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        async beforeUpload(file) {
          if (file.type) {
            const urlResponse = await urlSigned(file.type);
            if (urlResponse.status === 200) {
                file = createFile([file], urlResponse.nameFile, {
                    type: file.type,
                });
                file.urlSigned = urlResponse.url;
                }
            }
            setFiles(file);
            return file;
        },
        onRemove() {
            const currentActivityCopy = {...currentActivity};
            delete currentActivityCopy.encodingStatus;
            delete currentActivityCopy.temp_img_url;
            setActivity(currentActivityCopy);
        },
    };

    const [files, setFiles] = useState(null);
    const [activityKey, getActivityKey] = useState(null);
    const [currentActivity, setActivity] = useState(null);
    const [loading, loadingEvent] = useState(false);

    useEffect(() => {
        const params = getParams();
        if(params.k) {
            getActivityKey(params.k);
            getFromNoCacheActivity(params.k);
            async function getFromNoCacheActivity(id){
                setActivity(await getActivity(id));
            }
        }
    }, [activityKey]);

    const onChange = async (checked) =>{
        setActivity({...currentActivity, active: checked});
        return false;
    }

    const changeName = async e =>{
        setActivity({...currentActivity, name: e.target.value});
        return true;
    }

    const saveActivity = async _ =>{
      //update activity handler event
      if (activityKey) {
        loadingEvent(true);
        // if are an file on encoding process, send the encoding trigger to the server
        if (currentActivity.encodingStatus==="encoding" && currentActivity.temp_img_url) {
          const trancodingResponse = await encodingFile(
            getFileType(files.type),
            currentActivity.temp_img_url,
            activityKey,
            {activity: true},
          );
          if (trancodingResponse === "No file allow"){
            delete currentActivity.temp_img_url;
            delete currentActivity.encodingStatus;
            // print an error on the screen
          }
        }
        delete currentActivity.key;
        await updateActivity(activityKey, currentActivity);
        loadingEvent(false);
      }
    }

    if(!currentActivity) return null;

    if(loading) return (<Loading />);

    return (
        <div>
            <h4 className="section-title">Editar</h4>
            <Row>
                <Col span={12}>
                    <div className="card-edit">
                       <div className="label">
                           Nombre
                            <Input
                                defaultValue={currentActivity.name}
                                value={currentActivity.name}
                                onChange={changeName}
                                placeholder="Nombre"
                                maxLength={30}
                                className="edit-name"
                            />
                        </div>
                        <Dragger
                            {...props}
                            className="dragger-edit"
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                Haz click o arrastra una imagen a esta área para subirla.
                            </p>
                        </Dragger>
                        Activo
                        <Switch
                            defaultChecked={currentActivity.active}
                            onChange={onChange}
                            className="switch-edit"
                        />
                        <Button
                            className="save-edit"
                            onClick={saveActivity}
                        >
                            Guardar Cambios
                        </Button>
                    </div>
                </Col>
                <Col span={12}>
                    <div 
                        className="card-edit"
                        style={{position: 'relative'}}
                    >
                        <img 
                            src={
                              currentActivity.encodingStatus==="encoding" && currentActivity.temp_img_url
                                ? currentActivity.temp_img_url
                                : currentActivity.img_url
                            }
                            alt="preview"
                            className="img-preview"
                        />
                        <div className="title-preview">
                            {currentActivity.name}
                        </div>
                    </div>
                </Col>
            </Row>
            
        </div>
    )
}

export default ActivityContent
