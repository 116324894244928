import React, { useState } from 'react'
import "antd/dist/antd.css";
import './act.css';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';

import { UnorderedListOutlined, BellOutlined } from '@ant-design/icons';
import MapIcon from "../../Icon/MapIcon";
import LogoutIcon from "../../Icon/LogoutIcon/";
import logo from '../../Img/logo1873.png';

import auth from '../../../api/firebase/auth';

const { Sider } = Layout;

const SiderBar = (props) => {
    const {
        active
    } = props;
    const [collapsed, doCollapse] = useState(false);

    const onCollapse = (collapsed) => {
        doCollapse(collapsed)
    };

    const goPrincipal = () =>{
        var toContract = document.getElementById("goTo");
        toContract.click();
    }

    const goMaps = () =>{
        var toContract = document.getElementById("goToMap");
        toContract.click();
    }

    const goNotifications = () =>{
        var toPushNotif = document.getElementById("goToNtf");
        toPushNotif.click();
    }

    return (
       <Sider 
            collapsible 
            collapsed={collapsed} 
            onCollapse={onCollapse}
        >
            <div className="logo">
                <img
                    alt="logo"
                    src={logo}
                />
            </div>
            <Menu 
                theme="dark"
                defaultSelectedKeys={['1']} 
                mode="inline"
            >
                <Menu.Item
                    key={1}
                    icon={
                        <UnorderedListOutlined />
                    }
                    onClick={goPrincipal}
                    className={active !== 1 ? "unselected-bar" : ""}
                >
                    Actividades
                </Menu.Item>
                <Menu.Item 
                    key={2} 
                    icon={
                        <MapIcon
                            color={active === 2 ? "#fff" : "#9ea7af"} 
                        />}
                    onClick={goMaps}
                    className={active === 2 ? "selected-bar" : ""}
                >
                    Mapa Interactivo
                </Menu.Item>
                <Menu.Item 
                    key={3} 
                    icon={
                        <BellOutlined color={active === 3 ? "#fff" : "#9ea7af"} />}
                    onClick={goNotifications}
                    className={active === 3 ? "selected-bar" : ""}
                >
                    Notificaciones
                </Menu.Item>
                <Menu.Item 
                    key={4}
                    icon={
                        <LogoutIcon
                            color={active === 4 ? "#fff" : "#9ea7af"} 
                        />} 
                    onClick={() => auth.logOut()}
                >
                    Cerrar Sesión
                </Menu.Item>
            </Menu>
            <Link to="/" id="goTo"></Link>
            <Link to="/map" id="goToMap"></Link>
            <Link to="/notifications" id="goToNtf"></Link>
        </Sider>
    )
}

export default SiderBar
