import React, {useEffect, useState} from 'react'
import '../Activities.css';
import "antd/dist/antd.css";
import { Input, Upload, message, Switch, Button, Row, Col} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import getParams from "../../SearchParams/SearchParams";
import {getActivityObject, updateActivityObject} from "../../../api/calls/activity_object";
import Loading from "../../../containers/Loading";
import {uploadTempFile, urlSigned, createFile, encodingFile, getFileType} from "../../UploadFile/UploadFile";
import MediaRender from "./MediaRender/MediaRender";

const Subsection = ({getSubsection, name}) => {
    const { Dragger } = Upload;
    const { TextArea } = Input;
    const props = {
      name: 'file',
      multiple: false,
      maxCount: 1,
      customRequest: async options => {
        const { onSuccess, onError, onProgress } = options;
        const uploaded = await uploadTempFile(files.urlSigned, files, onProgress);
        if (uploaded) {
          files.success = true;
          files.url= uploaded;
          // set encoding flag
          activityObj.encodingStatus="encoding";
          setActivityObj(activityObj);
          onSuccess("Ok");
          return uploaded;
        }
        else {
          onError({err: uploaded})
          return false;
        }
      },
      accept: "image/*,video/*,audio/*",
      async onChange(info) {
        const activityObjCopy = {...activityObj};
        const { status, type } = info.file;
        if (status === 'done') {
          if(files.url){
            const urlResponse = await getFileType(type);
            if (urlResponse === "image"){
              activityObjCopy.temp_img_url = files.url;
            }
            else if (urlResponse === "video") {
              activityObjCopy.temp_video_url = files.url;
            }
            else if (urlResponse === "audio") {
              activityObjCopy.temp_audio_url = files.url;
            }
            setActivityObj(activityObjCopy);
          }
        } else if (status === 'error') {
          activityObjCopy.encodingStatus = "errorUploading";
          setActivityObj(activityObjCopy);
          // print an error on the screen
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      async beforeUpload(file) {
        if (file.type) {
          const urlResponse = await urlSigned(file.type);
          if (urlResponse.status === 200) {
            file = createFile([file], urlResponse.nameFile, {
              type: file.type,
            });
            file.urlSigned = urlResponse.url;
          }
        }
        setFiles(file);
        return file;
      },

      onRemove() {
        const activityObjCopy = {...activityObj};
        delete activityObjCopy.encodingStatus;
        delete activityObjCopy.temp_img_url;
        delete activityObjCopy.temp_audio_url;
        delete activityObjCopy.temp_video_url;
        setActivityObj(activityObjCopy);
      },
    };

    const [activityObj, setActivityObj] = useState({});
    const [loading, loadingEvent] = useState(false);
    const [activityObjKey, setActivityKey] = useState(null);
    const [files, setFiles] = useState(null);
    const [tempName, setTempName] = useState(name);

    useEffect(() => {
      const params = getParams();
      if(params.k) {
        setActivityKey(params.k);
        getFromNoCacheActivityObj(params.k);
        async function getFromNoCacheActivityObj(id){
          await getActivityObject(id, setActivityObj);
        }
      }
    }, []);

    const onChange = async (checked) =>{
      setActivityObj({...activityObj, active: checked});
      return false;
    }

    const changeName = async e =>{
      setActivityObj({...activityObj, name: e.target.value});
      return true;
    }

    const changeDescription = async e =>{
      setActivityObj({...activityObj, description: e.target.value});
      return true;
    }

    const saveActivityObject = async _ =>{
      //update activity handler event
      if (activityObjKey) {
        loadingEvent(true);
        // if are an file on encoding process, send the encoding trigger to the server
        let activityTempFile = activityObj.temp_img_url || activityObj.temp_video_url || activityObj.temp_audio_url;
        if (activityObj.encodingStatus==="encoding" && (activityTempFile)) {
          const trancodingResponse = await encodingFile(
            getFileType(files.type),
            activityTempFile,
            activityObjKey,
            {activityObject: true},
          );
          if (trancodingResponse === "No file allow"){
            delete activityObj.temp_img_url;
            delete activityObj.temp_video_url;
            delete activityObj.encodingStatus;
            delete activityObj.temp_audio_url;
            // print an error on the screen
          }
        }
        delete activityObj.key;
        await updateActivityObject(activityObjKey, activityObj);
        getSubsection(activityObj.name)
        if (tempName != activityObj.name) window.history.replaceState(null, "Observatorio Panel", window.location.href.replace(tempName, activityObj.name))
        loadingEvent(false);
      }
    }

    if(!activityObj.id_activity) return null;

    if(loading) return (<Loading />);

    return (
        <div>
            <Row>
                <Col span={12}>
                    <div className="card-edit subsection-height">
                       <div className="label">
                           Nombre
                            <Input
                                value={activityObj.name}
                                defaultValue={activityObj.name}
                                placeholder="Nombre"
                                maxLength={30}
                                className="edit-name"
                                onChange={changeName}
                            />
                        </div>
                        <h5 className="desc-title">Descripción</h5>
                        <TextArea
                            defaultValue={activityObj.description}
                            value={activityObj.description}
                            placeholder="Escribir descripción"
                            autoSize={{ minRows: 8, maxRows: 8 }}
                            className="description"
                            maxLength={1000}
                            onChange={changeDescription}
                        />
                        <Dragger
                            {...props}
                            className="dragger-edit"
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                Haz click o arrastra una imagen a esta área para subirla.
                            </p>
                        </Dragger>
                        Activo
                        <Switch
                            defaultChecked={activityObj.active || false }
                            onChange={onChange}
                            className="switch-edit"
                        />
                        <Button
                            className="save-edit"
                            onClick={saveActivityObject}
                        >
                            Guardar Cambios
                        </Button>
                    </div>
                </Col>
                <Col span={12}>
                    <div 
                        className="card-edit subsection-height"
                        style={{position: 'relative'}}
                    >
                        <MediaRender
                          activityObj={activityObj}
                        />
                        <h5 className="preview-title">Descripción</h5>
                        <p className="text-desc">
                          {activityObj.description}
                        </p>  
                    </div>
                </Col>
            </Row>
            
        </div>
    )
}

export default Subsection
