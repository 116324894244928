import React from "react";
const MediaRender = ({activityObj}) => {
    let {img_url, video_url, audio_url, temp_img_url, temp_video_url, temp_audio_url, encodingStatus, imageStyle} = activityObj;
    if (encodingStatus==="encoding") {
      img_url = temp_img_url;
      video_url = temp_video_url;
      audio_url = temp_img_url ? audio_url : temp_audio_url;
    }
    return(
      <div className="center">
        {!video_url && img_url
          ?
            <img
              style={imageStyle}
              src={img_url}
              alt="preview"
              className="img-subsect"
            />
          : video_url ?
            <video
              poster={img_url}
              controls
              src={video_url}
              className="img-subsect"
            />
            : null
        }
        {(!video_url && audio_url) && <audio src={audio_url} controls> Your browser does not support the <code>audio</code> element.</audio>}
      </div>
    )
}
export default MediaRender