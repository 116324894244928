import React, { useEffect, useRef, useState } from 'react'
import { Layout, Breadcrumb } from 'antd';
import "antd/dist/antd.css";
import '../act.css';
import SiderBar from '../../../components/Layout/SiderBar';
import getParams from "../../../components/SearchParams/SearchParams";
import Subsection from '../../../components/Activities/Subsection';
import ActivityInfo from '../../../components/Activities/ActivityInfo';
import ActivityContent from '../../../components/Activities/ActivityContent';

import { useHistory } from 'react-router-dom'

const Activity = () => {
    const { Header, Content } = Layout;
    const [section, currSection] = useState(null);
    const [selected, contentSection] = useState(null);
    const [subsect, getSubsection] = useState(null);
    const [keySection, getKeySection] = useState(null);
    const [locationKeys, setLocationKeys] = useState([])
    const direction = useRef(null);
    const history = useHistory()

    useEffect(() => {
        const params = getParams();
        if(params.a){
            currSection(params.a)
        } else if (params.c && !params.s) {
            contentSection(params.c)
            getKeySection(params.k)
        } else if(params.s && params.c){
            contentSection(params.c)
            getSubsection(params.s)
        }
        
        return history.listen(location => {
            if (history.action === 'PUSH') {
              setLocationKeys([ location.key ])
            }
        
            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    // Handle forward event
                    setLocationKeys(([ _, ...keys ]) => keys)
                    direction.current.click()
                } else {
                    // Handle back event
                    setLocationKeys((keys) => [ location.key, ...keys ])
                }
            }
        })

    }, [locationKeys]);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <SiderBar
                active = {1}
            />
            <Layout
                className="site-layout"
            >
                <Header
                    className="site-layout-background" 
                    style={{ padding: 0 }}
                />
                <Content 
                    style={{ margin: '0 16px' }}
                >
                    <Breadcrumb
                        style={{ margin: '16px 0', textTransform: 'capitalize' }}
                    >
                        <Breadcrumb.Item>
                            <a href="/">Actividades</a> /&nbsp;
                            {section 
                                ? section 
                                : (selected && !subsect) 
                                    ? selected
                                    : (selected && subsect) ? 
                                        <div className="inline"> 
                                            <a ref={direction} href={"/activity?c="+selected+"&k="+keySection}>{selected}</a> / {subsect}
                                        </div>
                                : null
                            }
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div 
                        className="site-layout-background table-act" 
                        style={{ padding: 24, minHeight: 360 }}
                    >
                        { section ?
                            <ActivityInfo />
                        : (selected && !subsect) ?
                            <ActivityContent
                                contentSection={contentSection}
                                currSection={currSection}
                                getSubsection={getSubsection}
                                nameSection={selected}
                                keySection={keySection}
                            />
                        : (selected && subsect) ?
                            <Subsection
                                name={subsect}
                                getSubsection={getSubsection}
                            />
                        : null }
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default Activity
