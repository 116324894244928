import React from 'react';

const LogoutIcon= ({className = null})=>{
    return(
        <svg  
            xmlns="http://www.w3.org/2000/svg" 
            width="14.977"
            height="14.932"
            viewBox="0 0 26.977 26.932"
            className={className}
        >
            <g 
                id="Group_14471" 
                data-name="Group 14471" 
                transform="translate(-400.994 -452.912)"
            >
            <path 
                id="Path_8640" 
                data-name="Path 8640" 
                d="M418.974,455.224h-8.45l-.029.135q1.259.39,2.517.78c1.384.427,1.47.537,1.47,1.943q0,8.089,0,16.178v1.08h4.491c0-1.525,0-2.994,0-4.462,0-.353-.015-.708.014-1.059.058-.7.4-1.169,1.156-1.138a1.067,1.067,0,0,1,1.078,1.215c.01,1.765,0,3.531,0,5.3,0,2.43,0,2.43-2.4,2.43h-4.176c-.08.134-.14.187-.138.238.052,2.187-.761,2.146-2.343,1.818-3.225-.669-6.458-1.3-9.689-1.946-1.263-.252-1.485-.51-1.485-1.8q0-10.641,0-21.281c0-1.418.322-1.732,1.768-1.732q8.382,0,16.764,0c1.391,0,1.7.306,1.7,1.689,0,1.926-.012,3.852.007,5.778.008.8-.2,1.425-1.1,1.505-.7.062-1.143-.516-1.152-1.46C418.963,458.728,418.974,457.029,418.974,455.224Z" 
                fill="#697078"
            />
            <path 
                id="Path_8641" 
                data-name="Path 8641" 
                d="M490.355,499.363c-2.18,0-4.04,0-5.9,0-1.045,0-1.573-.4-1.524-1.158.061-.945.735-1.094,1.5-1.094h5.835c-.546-.553-.917-.922-1.281-1.3-.51-.526-.693-1.115-.134-1.694a1.078,1.078,0,0,1,1.691.074q1.58,1.549,3.133,3.126a1.167,1.167,0,0,1,0,1.851q-1.564,1.565-3.134,3.124a1.088,1.088,0,0,1-1.695.068,1.047,1.047,0,0,1,.09-1.615C489.337,500.344,489.748,499.954,490.355,499.363Z" 
                transform="translate(-66.163 -32.969)" 
                fill="#697078"/>
            </g>
        </svg>
    )
}
export default LogoutIcon ;

